import { ProductInformationFileType } from './product-information-file.model';
export interface DateRange {
  from?: Date;
  until?: Date;
}
export enum DateSorting {
  Latest = 'LATEST',
  Oldest = 'OLDEST',
}
export interface FilterCriteria {
  fileTypes: ProductInformationFileType[];
  dateSorting?: DateSorting;
  fileLanguages: string[];
  nameFilter?: string;
}
