import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Option } from '../../../../shared/component/multiselect/multiselect.component';
import { ProductInformationFile, ProductInformationFileType } from '../../model/product-information-file.model';
import { DateSorting, FilterCriteria } from '../../model/product-information-filter-criteria.model';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

export interface FilterCriteriaChanged {
  filterCriteria: FilterCriteria;
}

@Component({
  selector: 'app-product-information-files-filter',
  templateUrl: './product-information-files-filter.component.html',
})
export class ProductInformationFilesFilterComponent implements OnInit, OnChanges {
  @Input() files: ProductInformationFile[] | undefined;
  @Input() languages: string[] | undefined;

  private static fileTypeFilterSessionStorageKey = 'app-product-file-list-selected-files';

  filterableFileTypes: Option[] = [];
  filterableFileLanguages: Option[] = [];
  dateSortingOptions: Option[] = [];
  searchQuery: FormControl = new FormControl('');

  selectedFileTypes: ProductInformationFileType[] = [];
  selectedFileLanguages: string[] = [];

  @Output() filterCriteriaChanges = new EventEmitter<FilterCriteriaChanged>();
  dateSorting: DateSorting = DateSorting.Latest;
  
  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.selectedFileLanguages = [this.translocoService.getActiveLang()];
    this.dateSortingOptions = Object.values(DateSorting).map(value => {
      return {
        value,
        label: this.translocoService.translate(`product-information.date-sorting.enum.${value}`),
      } as Option;
    });

    this.filterCriteriaChanges.emit({
      filterCriteria: {
        fileTypes: [...this.selectedFileTypes],
        dateSorting: this.dateSorting,
        fileLanguages: this.selectedFileLanguages,
      },
    });
    
    this.searchQuery.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.updateFilter();
    })
  }

  ngOnChanges() {
    this.languages?.map(response => {
      const language: Option = { value: response, label: response };
      this.filterableFileLanguages?.push(language);
    });
    this.filterableFileLanguages = this.createUniqueOptions(this.filterableFileLanguages);

    //I filter the values to eliminate SoftwareEltra from the filter options.
    this.filterableFileTypes = Object.entries(ProductInformationFileType)
      .filter(([key, value]) => value !== ProductInformationFileType.SoftwareEltra)
      .map(([key, value]) => {
        return {
          value: key,
          label: this.translocoService.translate(`product-information-files.file-type.enum.${key}`),
        } as Option;
      });
  }

  updateFilter(): void {
    this.filterCriteriaChanges.emit({
      filterCriteria: {
        fileTypes: [...this.selectedFileTypes],
        dateSorting: this.dateSorting,
        fileLanguages: [...this.selectedFileLanguages],
        nameFilter: this.searchQuery.value,
      },
    });
  }

  private createUniqueOptions(items: Option[] | undefined) {
    if (!items) return [];
    return [
      ...items.reduce((acc, curr) => {
        acc.add(curr.value);
        return acc;
      }, new Set<string>()),
    ].map(value => {
      return {
        value,
        label: this.translocoService.translate(`language.${value.toString()}`),
      };
    });
  }
}
