<ng-container *transloco="let t">
  @for (type of fileTypes; track type; let i = $index) {
    @if (filesByFileType(filteredFiles, type); as filteredFilesByType) {
      <div>
        @if (filteredFilesByType.length > 0) {
          <div>
            <div [class.mt-15]="i > 0" class="d-flex justify-content-start align-items-center">
              <h5 class="mr-3">{{ t('product-information-files.file-type.enum.' + type) }}</h5>
              @if (isFilesLoading) {
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              }
            </div>
            @for (file of filteredFilesByType; track file.id) {
              <div>
                @if (file) {
                  <app-accordion-card class="mt-5">
                    <div firstHalfSection class="d-flex align-items-center">
                      @if (file.type !== productInformationFileType.Software) {
                        <i
                          class="fa fa-file-pdf-o pr-2"
                        aria-hidden="true"></i>
                      }
                      @if (file.type === productInformationFileType.Software) {
                        <fa-icon
                          class="pr-2"
                        [icon]="softwareIcon"></fa-icon>
                      }
                      <h5 class="py-5 item-title m-0">{{ file?.name || '' }}</h5>
                    </div>
                    <ng-container secondHalfSection>
                      @if (file.modifiedAt) {
                        <div class="px-2 meta-item">
                          {{ t('product-information-files.publication-date') }}:
                          {{ file.modifiedAt | translocoDate : { dateStyle: 'short' } : (locale$ | async) || '' }}
                        </div>
                      }
                      @if (
                        { language: t('language.' + file.languageCode) } && file.type !== productInformationFileType.Software
                        ) {
                        @for (keyValueItem of { language: t('language.' + file.languageCode) } | keyvalue; track keyValueItem.key) {
                          <div>
                            @if (keyValueItem) {
                              <div class="px-2 meta-item">
                                {{ t('product-information-files.' + keyValueItem.key) }}: {{ keyValueItem.value }}
                              </div>
                            }
                          </div>
                        }
                      }
                      @if (asContentLengthString(file)) {
                        <div class="px-2 meta-item">
                          {{ t('product-information-files.size') }}: {{ asContentLengthString(file) | bytes }}
                        </div>
                      }
                      @for (action of createActions(file.id); track action.link) {
                        <app-button
                          buttonClass="{{ action.type?.valueOf() }} ml-1"
                          [icon]="downloadIcon"
                          title=""
                          [handler]="action.handler ? action.handler : noopHandler"
                          [disabled]="action.isEnabled && !action.isEnabled()"
                          [isWorkInProgress]="action.isWorking && action.isWorking()">
                        </app-button>
                      }
                    </ng-container>
                  </app-accordion-card>
                }
              </div>
            }
          </div>
        }
      </div>
    }
  }

  @if (files) {
    @if (files.length === 0) {
      <div class="card-body">
        <p class="text-muted">{{ t('product-information-files.no-files') }}</p>
      </div>
    }
  }
</ng-container>
