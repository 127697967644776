<ng-container *transloco="let t">
  <div class="container px-lg-5">
    @if (deviceModelReference) {
      <app-categorized-page-title
        [category]="deviceModelReference.id | rebrandDeviceModel"
        [title]="t('product-information-files')"></app-categorized-page-title>
    }
    @if (!isLoading) {
      <div class="row">
        <div class="col-12">
          <app-product-information-files-filter
            [files]="files"
            [languages]="languages"
            (filterCriteriaChanges)="onFilterCriteriaChanged($event)">
          </app-product-information-files-filter>
        </div>
        <div class="col-12">
          <app-product-information-files-listing
            [files]="files"
            [fileTypes]="types"
            [filterCriteria]="filterCriteria"
            [isFilesLoading]="isFilesLoading">
          </app-product-information-files-listing>
          @if (canLoadMore) {
            <app-button
              [title]="t('load-more')"
              [buttonClass]="'btn-outline-vendor mt-3'"
              [isWorkInProgress]="isFilesLoading"
              [handler]="loadMore.bind(this)"
            ></app-button>
          }
        </div>
      </div>
    } @else {
      <app-loading-indicator></app-loading-indicator>
    }
  </div>
</ng-container>

