import { Component, Injector, OnDestroy, OnInit, effect } from '@angular/core';
import { DeviceModelReference, DeviceVendor } from 'src/app/device/device-shared/model/device.model';
import { ProductInformationService } from 'src/app/product-information/service/product-information.service';
import { Subscriptions } from 'src/app/shared/util/Subscriptions';
import { ProductInformationFile, ProductInformationFileType } from '../../model/product-information-file.model';
import { FilterCriteria } from '../../model/product-information-filter-criteria.model';
import { FilterCriteriaChanged } from './product-information-files-filter.component';
import { TranslocoService } from '@ngneat/transloco';
import { SharedDeviceService } from '../../../../device/device-shared/service/shared-device.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-product-information-files',
  templateUrl: './product-information-files.component.html',
})
export class ProductInformationFilesComponent implements OnInit, OnDestroy {
  deviceModelReference?: DeviceModelReference;
  subscriptions: Subscriptions = new Subscriptions();

  files: ProductInformationFile[] | undefined = [];
  currentPageFiles: ProductInformationFile[] | undefined = [];
  languages: string[] | undefined = [];
  types: ProductInformationFileType[] | undefined = [];
  canLoadMore: boolean = true;
  filterCriteria?: FilterCriteria;

  isLoading: boolean = true;
  isFilesLoading: boolean = false;

  constructor(
    private productInformationService: ProductInformationService,
    private translocoService: TranslocoService,
    private injector: Injector,
    private sharedDeviceService: SharedDeviceService
  ) {}

  ngOnInit() {
    const device = this.sharedDeviceService.device()?.data;

    const vendor = device && device.vendor;
    const model = device && device.model.identification.id;
    const version = device && device.model.identification.version;

    this.deviceModelReference = {
      vendor: vendor as DeviceVendor,
      id: model || '',
      version: version || '',
    };

    this.filterCriteria = { fileLanguages: [this.translocoService.getActiveLang()], fileTypes: [] };

    effect(
      () => {
        this.languages = this.productInformationService.languages();

        this.types = this.productInformationService.types();
        this.types?.forEach((type, index, array) => {
          if (type.toString() === ProductInformationFileType.SoftwareEltra) {
            array[index] = ProductInformationFileType.Software;
          }
        });

        this.currentPageFiles = this.productInformationService.files()?.collection.map(item => item.data) || [];
        this.currentPageFiles.forEach(file => {
          if (file.type.toString() === ProductInformationFileType.SoftwareEltra)
            file.type = ProductInformationFileType.Software;
        });

        if (this.productInformationService.page == 0) {
          this.files = this.currentPageFiles;
        } else {
          this.files = this.files?.concat(this.currentPageFiles);
        }

        this.canLoadMore = true;
        this.isLoading = false;

        if (this.currentPageFiles.length < this.productInformationService.pageSize) {
          this.canLoadMore = false;
        }
      },
      { injector: this.injector }
    );
  }

  getData(): void {
    this.isFilesLoading = true;
    this.subscriptions.put(
      this.productInformationService
        .getAll(
          this.deviceModelReference!,
          this.filterCriteria,
          this.productInformationService.page,
          this.productInformationService.pageSize
        )
        .pipe(finalize(() => this.isFilesLoading = false))
        .subscribe()
    );
  }

  loadMore(): void {
    this.productInformationService.page = this.productInformationService.page + 1;
    this.getData();
  }

  onFilterCriteriaChanged(event: FilterCriteriaChanged): void {
    if (this.filterCriteria?.dateSorting == event.filterCriteria.dateSorting) {
      this.filterCriteria = event.filterCriteria;
      this.productInformationService.page = 0;
      this.getData();
    } else {
      this.filterCriteria = event.filterCriteria;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }
}
