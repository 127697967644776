import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, interval, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ICoreApiResult } from '../../../core/model/core-api-result.model';
import { CoreUser } from '../../../core/model/core-user.model';
import {
  PrepareDeviceRegistrationResult,
  UnregisterDeviceResult,
  Device,
  DeviceSummary,
  DeviceToken,
  DeviceTokenRequest,
  PrepareDeviceRegistrationResponse, DeviceRegistrationProperties
} from '../model/device.model';
import { ApiDevice, ApiDeviceSummary } from '../model/api-device.model';
import {
  Collection,
  CollectionItem,
  createResourceEmbeddedCollection,
  GetCollectionResponse,
  HasLink,
  preparePermissions,
} from '../../../access-rights/permissions.utils';

import { handleError } from '../../../shared/util/http-utils';
import { CanDelete, CanReadCollection, CanReadSelf } from '../../../access-rights/permissions/permission-models';

export interface DeviceCollectionPermissions extends CanReadSelf {}
export interface DevicePermissions extends CanDelete, CanReadCollection, CanReadSelf {
  current_notifications: boolean;
  move_to_virtual_laboratory: boolean;
  journal_items: boolean;
  remote_control_requests: boolean;
  files_logs: boolean;
  files_backups: boolean;
  supported_remote_control_request_types: boolean;
}

export interface DeviceResponse extends ApiDevice, HasLink {}
export type DeviceSummaryCollectionResult = Collection<DeviceSummary, DeviceCollectionPermissions, DevicePermissions>;
export type DeviceResult = CollectionItem<Device, DevicePermissions>;

export function mapDeviceSummary(device: ApiDeviceSummary): DeviceSummary {
  return {
    id: device.id,
    virtualLaboratory: device.virtualLaboratory,
    name: device.name,
    description: device.description,
    vendor: device.vendor,
    type: device.type,
    model: device.model,
    status: device.status,
    firmwares: device.firmwares,
    notificationSummary: device.notificationSummary,
    serialNumber: device.serialNumber,
    statistics: device.statistics,
    genericProperties: device.genericProperties,
    isOffline: device.isOffline,
  } as DeviceSummary;
}

function mapDevice(data: ApiDevice): Device {
  return {
    id: data.id,
    virtualLaboratory: data.virtualLaboratory ?? data.userGroup,
    name: data.name,
    description: data.description,
    vendor: data.vendor,
    type: data.type,
    model: data.model,
    status: {
      connection: {
        type: data.status.connection.type,
        lastUpdatedAt: data.status.connection.lastUpdatedAt
          ? new Date(data.status.connection.lastUpdatedAt)
          : undefined,
      },
      operational: data.status.operational,
    },
    serialNumber: data.serialNumber,
    firmwares: data.firmwares,
    statistics: data.statistics,
    platformSettings: data.platformSettings,
    genericProperties: data.genericProperties,
    reportedPropertiesUpdatedAt: data.reportedPropertiesUpdatedAt
      ? new Date(data.reportedPropertiesUpdatedAt)
      : undefined,
  } as Device;
}

@Injectable({
  providedIn: 'root',
})
export class SharedDeviceApiService {
  private getConnectionStringUrl: string =
    environment.backendApi.shared.uri +
    '/GetConnectionString?code=' +
    environment.backendApi.shared.key +
    '&clientId=' +
    environment.backendApi.shared.id;
  private getSystemCheckHistoryUrl: string =
    environment.backendApi.shared.uri +
    '/GetSystemChecks?code=' +
    environment.backendApi.shared.key +
    '&clientId=' +
    environment.backendApi.shared.id;

  private http = inject(HttpClient);
  getDeviceById(deviceId: string): Observable<{ data: Device; permissions: DevicePermissions } | undefined> {
    if (!deviceId) {
      return of(undefined);
    }
    return this.http.get<DeviceResponse>(`${environment.backendApi.shared.uri}/devices/${deviceId}`).pipe(
      map(response => {
        return {
          data: mapDevice(response),
          permissions: preparePermissions<DevicePermissions>(response?._links),
        };
      })
    );
  }

  startDeviceByIdPoller(deviceId: string): Observable<DeviceResult | undefined> {
    if (!deviceId) {
      return of(undefined);
    }
    return interval(5000).pipe(
      switchMap(() => {
        return this.http.get<DeviceResponse>(`${environment.backendApi.shared.uri}/devices/${deviceId}`).pipe(
          switchMap(response => {
            return of({
              data: mapDevice(response),
              permissions: preparePermissions<DevicePermissions>(response?._links),
            });
          })
        );
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          const httpErrorResponse: HttpErrorResponse = error;
          switch (httpErrorResponse.status) {
            // ! we need the 404 to not be handled as an error yet because we are using the retryWhen operator in the device-registration.service.ts line 86 to check if the device is registered every time a 404 is returned
            // case 404:
            //   return of(undefined);
            default:
              throw error;
          }
        }
        throw error;
      })
    );
  }

  getDevicesByVirtualLaboratory(virtualLaboratoryId: string): Observable<DeviceSummaryCollectionResult> {
    return this.startDevicesPolling(virtualLaboratoryId);
  }

  getDevices(virtualLaboratoryId?: string): Observable<DeviceSummaryCollectionResult> {
    const url = new URL(`${environment.backendApi.shared.uri}/devices`);

    if (virtualLaboratoryId) {
      url.searchParams.append('virtualLaboratoryId', virtualLaboratoryId);
    }

    return this.http.get<GetCollectionResponse<ApiDeviceSummary>>(url.href).pipe(
      map(response => {
        return {
          collection: createResourceEmbeddedCollection<DeviceSummary, DevicePermissions>(
            response?._embedded,
            mapDeviceSummary
          ),
          permissions: preparePermissions<DeviceCollectionPermissions>(response._links),
        };
      }),
    );
  }

  startDevicesPolling(virtualLaboratoryId?: string): Observable<DeviceSummaryCollectionResult> {
    const url = new URL(`${environment.backendApi.shared.uri}/devices`);

    if (virtualLaboratoryId) {
      url.searchParams.append('virtualLaboratoryId', virtualLaboratoryId);
    }

    return interval(5000).pipe(
      switchMap(() => {
        return this.http.get<GetCollectionResponse<ApiDeviceSummary>>(url.href).pipe(
          map(response => {
            return {
              collection: createResourceEmbeddedCollection<DeviceSummary, DevicePermissions>(
                response?._embedded,
                mapDeviceSummary
              ),
              permissions: preparePermissions<DeviceCollectionPermissions>(response._links),
            };
          }),
          catchError(handleError)
        );
      })
    );
  }

  prepareDeviceRegistration(deviceRequest: DeviceTokenRequest): Observable<PrepareDeviceRegistrationResponse> {
    return this.http
      .post<DeviceToken>(`${environment.backendApi.shared.uri}/devices/registrations`, deviceRequest)
      .pipe(
        map(result => {
          return {
            token: result,
            resultStatus: PrepareDeviceRegistrationResult.Success,
          } as PrepareDeviceRegistrationResponse;
        }),
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            const httpErrorResponse: HttpErrorResponse = error;
            switch (httpErrorResponse.status) {
              case 403:
                return of({
                  resultStatus: PrepareDeviceRegistrationResult.NotAuthorized,
                } as PrepareDeviceRegistrationResponse);
              case 404:
                return of({
                  resultStatus: PrepareDeviceRegistrationResult.DoesNotExist,
                } as PrepareDeviceRegistrationResponse);
              case 422:
                return of({
                  resultStatus: PrepareDeviceRegistrationResult.NotAuthorized,
                } as PrepareDeviceRegistrationResponse);
              default:
                throw error;
            }
          } else {
            throw error;
          }
        })
      );
  }

  prepareDeviceOfflineRegistration(deviceProperties: DeviceRegistrationProperties): Observable<string> {
    const url = `${environment.backendApi.shared.uri}/devices/registrations/offline`;
    return this.http.post(url, { deviceProperties }, { responseType: 'text' })
      .pipe(catchError(({ error }) => throwError(() => JSON.parse(error))));
  }

  unregisterDevice(deviceId: string): Observable<UnregisterDeviceResult> {
    return this.http.delete(`${environment.backendApi.shared.uri}/devices/${deviceId}`).pipe(
      map(_ => UnregisterDeviceResult.Success),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          const httpErrorResponse: HttpErrorResponse = error;
          switch (httpErrorResponse.status) {
            case 403:
              return of(UnregisterDeviceResult.NotAuthorized);
            case 404:
              return of(UnregisterDeviceResult.DoesNotExist);
            case 422:
              return of(UnregisterDeviceResult.UnprocessableRequest);
            default:
              throw error;
          }
        } else {
          throw error;
        }
      })
    );
  }

  getConnectionString(user: CoreUser | undefined, device: string): Observable<HttpResponse<ICoreApiResult>> {
    return this.http.post<HttpResponse<ICoreApiResult>>(this.getConnectionStringUrl, {
      UserId: user?.UserId,
      SessionId: user?.SessionId,
      DeviceId: device,
    });
  }

  getSystemCheckHistory(user: CoreUser | undefined, device: string): Observable<ICoreApiResult> {
    return this.http.post<ICoreApiResult>(this.getSystemCheckHistoryUrl, {
      UserId: user?.UserId,
      SessionId: user?.SessionId,
      DeviceId: device,
    });
  }
}
