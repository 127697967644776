<ng-container *transloco="let t">
  <div class="list-toolbar row">
    <div class="col-12 col-lg-4">
      <app-multiselect
        name="fileTypesFilter"
        [(ngModel)]="selectedFileTypes"
        (change)="updateFilter()"
        [options]="filterableFileTypes | sortBy : 'asc' : 'label'"
        placeholderKey="product-information-files.filter.all-types-label">
      </app-multiselect>
    </div>
    <div class="col-6 col-lg-4">
      <app-radio-dropdown
        name="date-sorting"
        [options]="dateSortingOptions"
        (change)="updateFilter()"
        [(selected)]="dateSorting">
      </app-radio-dropdown>
    </div>
    <div class="col-6 col-lg-4">
      <app-multiselect
        name="releaseDateFilter"
        [(ngModel)]="selectedFileLanguages"
        (change)="updateFilter()"
        [options]="filterableFileLanguages | sortBy : 'asc' : 'label'"
        placeholderKey="product-information-files.filter.all-languages">
      </app-multiselect>
    </div>
    <div class="col-12">
      <app-input-new
        [formControl]="searchQuery"
        [placeholder]="t('product-information-files.filter.query')"
      ></app-input-new>
    </div>
  </div>
</ng-container>
